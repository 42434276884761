var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isBlockShown)?_c('div',{class:['panel', 'about_event',
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
	]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.isHeaderVisible)?_c('div',{class:[
						'panel_header',
						_vm.isBrand? 'sk' : '',
					]},[_c('h2',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.block.block_title)+"\n\t\t\t\t\t")])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg"},[_c('div',{staticClass:"event_overview"},[(_vm.block.description)?_c('div',{staticClass:"title mb-4"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.block.description)+"\n\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(_vm.block.text)?_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s( _vm.block.text)}}):_vm._e()])]),_vm._v(" "),(!_vm.block.right_image_disable)?_c('div',{staticClass:"col-12 col-lg-5 offset-lg-1",class:[_vm.block.embed_url ? 'mb-4' : '']},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-7 col-md-6 col-lg-7 order-sm-2"},[_c('div',{class:['event_brief_image',
								_vm.block.appearance? _vm.block.appearance : ''
							]},[_c('div',{staticClass:"bg-event",style:('background-image:url(' + _vm.fetchedData.top_cover_image_default.src + ')')},[_c('div',{staticClass:"event_date"},[(_vm.fetchedData.id === 4982 || _vm.fetchedData.id === 4952)?_c('span',[_vm._v("1-3")]):(_vm.fetchedData.id === 8792 || _vm.fetchedData.id === 8782)?_c('span',[_vm._v("7-9")]):(_vm.fetchedData.id === 5992 || _vm.fetchedData.id === 6022)?_c('span',[_vm._v("25-26")]):_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.fetchedData.event_date,"DD")))])]),_vm._v(" "),_c('div',{staticClass:"event_month"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("moment")(_vm.fetchedData.event_date,"MMMM"))+"\n\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"event-title"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.fetchedData.title)+"\n\t\t\t\t\t\t\t\t")])])])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-5 col-md-6 col-lg-5 order-sm-1"},[_c('div',{staticClass:"event_brief"},[(_vm.block.title_right)?_c('div',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.block.title_right)+"\n\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(_vm.block.description_right)?_c('p',{staticClass:"description"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.block.description_right)+"\n\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),_c('dynamic-button',{attrs:{"button":_vm.block.button,"block-id":_vm.block.id,"scenario":_vm.block.scenario}})],1)])])]):_vm._e(),_vm._v(" "),(_vm.block.embed_url)?_c('div',{staticClass:"col-12"},[_c('b-embed',{attrs:{"type":"iframe","src":_vm.block.embed_url + '?rel=0&enablejsapi=1'}})],1):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }