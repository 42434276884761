<template>
	<div
		v-if="isBlockShown"
		:class="['panel', 'about_event',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
		]"
	>
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div
						v-if="isHeaderVisible"
						:class="[
							'panel_header',
							isBrand? 'sk' : '',
						]"
					>
						<h2>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
				<div class="col-12 col-lg">
					<div class="event_overview">
						<div v-if="block.description" class="title mb-4">
							{{ block.description }}
						</div>
						<div v-if="block.text" v-html=" block.text" class="description" />
					</div>
				</div>
				<div
					v-if="!block.right_image_disable"
					class="col-12 col-lg-5 offset-lg-1"
					:class="[block.embed_url ? 'mb-4' : '']"
				>
					<div class="row">
						<div class="col-12 col-sm-7 col-md-6 col-lg-7 order-sm-2">
							<div
								:class="['event_brief_image',
									block.appearance? block.appearance : ''
								]"
							>
								<div
									class="bg-event"
									:style="'background-image:url(' + fetchedData.top_cover_image_default.src + ')'"
								>
									<div class="event_date">
										<span v-if="fetchedData.id === 4982 || fetchedData.id === 4952">1-3</span>
										<span v-else-if="fetchedData.id === 8792 || fetchedData.id === 8782">7-9</span>
										<span v-else-if="fetchedData.id === 5992 || fetchedData.id === 6022">25-26</span>
										<span v-else>{{ fetchedData.event_date | moment("DD") }}</span>
									</div>
									<div class="event_month">
										{{ fetchedData.event_date | moment("MMMM") }}
									</div>
									<div
										class="event-title"
									>
										{{ fetchedData.title }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-5 col-md-6 col-lg-5 order-sm-1">
							<div class="event_brief">
								<div
									v-if="block.title_right"
									class="title"
								>
									{{ block.title_right }}
								</div>

								<p v-if="block.description_right" class="description">
									{{ block.description_right }}
								</p>
								<dynamic-button
									:button="block.button"
									:block-id="block.id"
									:scenario="block.scenario"
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="block.embed_url"
					class="col-12"
				>
					<b-embed
						type="iframe"
						:src="block.embed_url + '?rel=0&enablejsapi=1'"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';
import checkIsBlockShown from '~/mixins/checkIsBlockShown';
import '@/utils/moment';

export default {
	name: 'PanelTe03',
	mixins: [panel, checkIsBlockShown],
};
</script>

<style lang="scss">
	@import "~/assets/styles/components/home/panel-about_program";
</style>
